require('./main.scss');
require('../src/brochure-popup/brochure-popup.js');

const {Offcanvas} = require("bootstrap");

$(() => {

    $('#openMobileMenu').click(() => {
        const offcanvas = new Offcanvas($('#mobileMenuModal'))
        offcanvas.show();
    });

    let fired = false;

    const siteToken = $('#siteToken').data('token');

    function handleScroll() {

        if (!fired) {
            const recaptchaScript = $('<script>');
            recaptchaScript.attr('src', `https://www.google.com/recaptcha/api.js?render=${siteToken}`);
            recaptchaScript.attr('defer', 'true');
            $('body').append(recaptchaScript);
            fired = true;
            $(window).off('scroll touchstart touchmove mousedown DOMMouseScroll mousewheel keyup', handleScroll);
        }
    }

    $(window).on('scroll touchstart touchmove mousedown DOMMouseScroll mousewheel keyup', handleScroll);

});


